exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-about-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/page-query.tsx?__contentFilePath=/opt/build/repo/content/pages/about/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-about-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-focus-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/Focus/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-focus-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-labrinth-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/Labrinth/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-labrinth-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-map-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/MAP/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-map-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-seashell-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/Seashell/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-seashell-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-technical-artist-technical-artist-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/TechnicalArtist/TechnicalArtist.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-technical-artist-technical-artist-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-vevent-tailor-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx?__contentFilePath=/opt/build/repo/content/projects/vevent-tailor/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx-content-file-path-content-projects-vevent-tailor-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

